import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import axios from 'axios';

export const ArticleDetail = () => {
  const {id} = useParams();
  console.log(id);
  const [article, setArticle] = useState('loading... ...');
  console.log('public url: ', process.env.PUBLIC_URL);

  useEffect(() => {
    document.title = '稷下学宫';
    axios.get(`./${id}.md`).then((response) => {
      setArticle(response.data);
      console.log(response.data);
    });
  }, [id]);

  return (
    <div className='Article'>
      <ReactMarkdown >{article}</ReactMarkdown>
    </div>
  );
};

export default ArticleDetail;
