import React, {useEffect, useState} from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import './SafeGPT.css';


function SafeGPT() {
  const [output, setOutput] = useState('');
  const [countOfProgress, setCountOfProgess] = useState(10);
  const prompt = '{"prompt": "推荐给我一本豆瓣网上的书，并告诉我豆瓣评分，推荐理由和书目录"}';


  function getGptData(prompt) {
    setCountOfProgess(10);

    // fetch('https://openai.lonelylearning.com/ask', {
    // fetch('http://localhost:4996/chatgpt', {
    fetch('https://openai.lonelylearning.com/chatgpt', {
      method: 'POST',
      body: prompt,
      headers: {
        'Content-Type': 'application/json',
      },
    }) .then((response) => response.json())
        .then((data) => {
          setCountOfProgess(100);
          console.log(data);
          setOutput(output+data.message + '\n\n');
        });
  }

  useEffect(() => {
    document.title = 'GPT Insight';
    getGptData(prompt);
    const timer = setInterval(() => {
      setCountOfProgess((oldProgress) => {
        // if (100 == oldProgress) return 0;
        return Math.min(oldProgress + Math.random() * 5, 100);
      });
    }, 800);

    return () => {
      clearInterval(timer);
    };
  }, []);

  function handleClick() {
    getGptData(prompt);
  }

  return (
    <div>
      <button className="button" onClick={handleClick}><h3>听GPT一本正经地胡说八道</h3></button>
      <p><b>Prompt: </b>推荐给我一本豆瓣网上的书，并告诉我豆瓣评分，推荐理由和书目录</p>
      <div>
        {
          countOfProgress < 100 && (<ProgressBar animated now={countOfProgress} />)
        }
      </div>
      <div style={{whiteSpace: `pre-wrap`}}>{output}</div>
    </div>
  );
}

export default SafeGPT;

