/* eslint-disable require-jsdoc */
import './App.css';
import React from 'react';
import {NavLink, Outlet} from 'react-router-dom';
import {Navbar, Container, Nav} from 'react-bootstrap';


function App() {
  return <>
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand>
          <NavLink to="/" style={{textDecoration: 'none', color: 'inherit'}}>Lonely Learning</NavLink>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavLink to="/articles" className="nav-link">Articles</NavLink>
            <NavLink to="/gpt" className="nav-link">GPT Insight</NavLink>
          </Nav>
        </Navbar.Collapse>

      </Container>
    </Navbar>
    <Container className="mt-3">
      <h1></h1>
      <Outlet />
    </Container>
  </>;
}

export default App;
