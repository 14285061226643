import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';

export const ArticleList = () => {
  const [articles, setArticles] = useState([]);
  const data = [
    {id: 1, title: '说还是不说'},
    {id: 2, title: '没有谎言的一天'},
    {id: 3, title: '超越自卑'},
    {id: 4, title: '爱的艺术'},
  ];
  useEffect(() => {
    document.title = '稷下学宫';
    setArticles(data);
  }, []);


  return (
    <div>
      <ul>
        { Array.from(articles).map((article) => (
          <li key={article.id}>
            {/* eslint-disable max-len */}
            <Link to={`/articles/${article.id}`}>{article.title}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ArticleList;
