import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ArticleList from './routes/ArticleList';
import ArticleDetail from './routes/ArticleDetail/';
import SafeGPT from './routes/SafeGPT/';

import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
      <BrowserRouter basename='/'>
        <Routes>

          <Route path="/" element={<App />} >
            <Route
              index
              element={
                <>
                  <h2>Welcome in 稷下学宫</h2>
                  <p>Please use the navigation above to see Articles.</p>
                </>
              }
            />
            <Route path="articles" element={<ArticleList />} />
            <Route path="articles/:id" element={<ArticleDetail />} />
            <Route path="gpt" element={<SafeGPT />} />

          </Route>
        </Routes>
      </BrowserRouter>
    </React.StrictMode>,
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
